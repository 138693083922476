.planTable {
  font-size: 1.3125rem;
  text-align: right;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.maxW900 {
  max-width: 900px;
}

.planTable td:nth-of-type(5),
.planTable th:nth-child(4) {
  border-right: 1px solid #636363;
}

.planTable th:first-child {
  width: 160px;
}

.mtdTooltip tr {
  text-align: right;
}

.mtdTooltip td:first-of-type {
  padding-right: 5px;
}

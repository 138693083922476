/* Grid default theme */

.GridRow {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  flex: 0 0 50px;
}

.BodyGrid {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}
.oddRow {
  background-color: #fafafa;
}

.bodyCell,
.headerCell,
.primaryColumnCell {
  width: 100%;
  height: 100%;
  display: table-cell;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyCell {
  text-align: right;
}
.headerCell {
  font-weight: bold;
  border-right: 1px solid #c7c7c7;
  border-right-style: dotted;
  border-top: 1px solid #1d1d1d;
  border-bottom: 1px solid #1d1d1d;
  text-align: right;
}
.centeredCell {
  align-items: center;
  text-align: center;
}
.primaryColumnCell {
  text-align: left;
}

.primaryColumnCell.rowHeaderCell {
  text-decoration: underline;
}

.rowSummaryCell {
  font-weight: bold;
  border-top: 1px solid #cccccc;
}

.noCells {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.hoveredItem {
  background-color: rgb(190, 190, 190);
}

.border-right-bold {
  border-right: 2px solid #999999 !important;
}

@import "~@coreui/coreui/scss/coreui.scss";

// Here you can add other styles
.inputBorder {
  border-color: $gray-200;
}

.btn.is-invalid {
  border-color: $red;
}

.card-deck .card {
  margin-bottom: 0px;
}

.is-invalid .react-select__control {
  border-color: $danger;
}

.is-invalid .DateRangePickerInput {
  border-color: $danger;
}

// Fix to line up year, month selects in Airbnb DateRangePicker
.CalendarMonth_caption {
  padding-top: 18px;
}

// Indent Nav Submenus - Issue #160
.nav .nav-dropdown-items li.nav-item {
  margin-left: 1.1em;
}

.cardInDeck {
  width: 320px;
  margin: 1rem;
  min-width: 320px;
  max-width: 320px;

  @media screen and (max-width: map-get($grid-breakpoints, sm)-1px) {
    margin-left: auto;
    margin-right: auto;
  }
}

// The spinner pushes the cursor to the right and it's annoying ux
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
